@import '../sass/our-bulma/utilities/_all.sass';
@import '../sass/our-bulma/base/_all.sass';
@import '../sass/our-bulma/grid/columns.sass';
@import '../sass/our-bulma/elements/container.sass';
@import '../sass/our-bulma/layout/hero.sass';

@import './landing.scss';
@import './input.scss';
@import './dropdowns.scss';
@import './faq.scss';

@font-face {
	font-family: 'David_Reg';
	src: local('David_temporary_03_Regular'),
		url(../fonts/David_temporary_03_Regular.otf) format('opentype');
}

@font-face {
	font-family: 'Graphik';
	src: local('Graphik-Regular'),
		url(../fonts/Graphik-Regular.otf) format('opentype');
}

@function get-line-height($font-size, $line-height) {
	@return $line-height / $font-size;
}

* {
	color: white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.App {
	min-height: 100vh;
	background-color: #292929;
}
