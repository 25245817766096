#input-page {
  background-color: #292929;
}

.inactive-grey {
  border-color: 'grey';
  color: 'grey';
}

.active-white {
  border-color: 'white';
  color: 'white';
}

#mobile-column-margin {
  margin: 0 10px;
}

.logo-size {
  position: -webkit-sticky;
  position: sticky;
  top: 12.5vh;
  height: 75vh;
}

#input-logo-mobile {
  position: -webkit-sticky;
  position: sticky;
  top: 20vh;
}

#four-k-close-btn {
  position: -webkit-sticky;
  position: sticky;
  top: 37.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 2px solid white;
  padding: 20px 30px;
  font-family: 'Graphik';
  font-size: 32px;
  outline: none;
  p {
    color: white;
    padding-left: 54px;
  }
  img {
    height: 22px;
  }
}

.close-btn {
  position: -webkit-sticky;
  position: sticky;
  top: 37.5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 15px;
  font-family: 'Graphik';
  font-size: 16px;
  outline: none;
  p {
    color: white;
    padding-left: 27px;
  }
}

#close-button-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 1px solid white;
  background-color: #292929;
  margin-top: 5vh;
  outline: none;
  padding: 10px 15px;
  font-family: 'Graphik';
  font-size: 16px;
  p {
    color: white;
    padding-left: 27px;
  }
}

#four-k-contact {
  top: 75vh;
  position: -webkit-sticky;
  position: sticky;
  p {
    font-size: 32px;
    line-height: get-line-height(32px, 44pt);
    font-family: 'Graphik';
    &:nth-child(3) {
      margin-top: 48px;
    }
  }
  a {
    text-decoration: none;
    color: white;
    font-weight: normal;
    font-family: 'Graphik';
    font-size: 32px;
    line-height: get-line-height(32px, 44pt);
  }
}

.input-contact-info {
  top: 75vh;
  position: -webkit-sticky;
  position: sticky;
  p {
    font-size: 16px;
    line-height: 22pt;
    font-family: 'Graphik';
    &:nth-child(3) {
      margin-top: 24px;
    }
  }
  a {
    text-decoration: none;
    color: white;
    font-weight: normal;
    font-family: 'Graphik';
    font-size: 16px;
    line-height: 22pt;
  }
}

.input-contact-info-mobile {
  margin-top: 13vh;
  p {
    font-size: 16px;
    line-height: 30px;
    font-family: 'Graphik';
    &:nth-child(3) {
      margin-top: 21pt;
    }
  }
  a {
    text-decoration: none;
    color: white;
    font-weight: normal;
    font-family: 'Graphik';
    line-height: 30px;
    font-size: 16px;
  }
}

#four-k-required {
  padding-top: 15vh;
  p {
    transform: rotate(90deg);
    font-size: 24px;
    font-family: 'Graphik';
    white-space: nowrap;
  }
}

.required-text {
  padding-top: 12.5vh;
  p {
    transform: rotate(90deg);
    font-size: 12px;
    font-family: 'Graphik';
    white-space: nowrap;
  }
}

.success {
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
}

.success-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
}

.success-inner-wrapper-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
}

.copyrightSuccess {
  display: flex;
  flex-direction: row;
  padding-top: 60vh;
  justify-self: flex-end;
  font-family: 'Graphik';
  p {
    color: grey;
    &#copyright-text {
      flex: 1;
      font-size: 24px;
      font-family: 'Graphik';
    }
    &#rights-text {
      flex: 1;
      display: flex;
      justify-self: flex-end;
      font-size: 24px;
      font-family: 'Graphik';
    }
  }
}

.copyrightSuccess {
  display: flex;
  flex-direction: row;
  padding-top: 60vh;
  justify-self: flex-end;
  font-family: 'Graphik';
  p {
    color: grey;
    &#copyright-text {
      flex: 1;
      font-size: 12px;
      font-family: 'Graphik';
    }
    &#rights-text {
      flex: 1;
      display: flex;
      justify-self: flex-end;
      font-size: 12px;
      font-family: 'Graphik';
    }
  }
}

.copyrightSuccess-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  justify-content: space-around;
  font-family: 'Graphik';
  p {
    color: grey;
    &#copyright-text-mobile {
      flex: 1;
      margin-bottom: 1vh;
      font-size: 12px;
      font-family: 'Graphik';
    }
    &#rights-text-mobile {
      flex: 1;
      display: flex;
      justify-self: flex-end;
      font-size: 12px;
      font-family: 'Graphik';
    }
  }
}

#four-k-start-text {
  font-size: 32px;
  margin-top: 10vh;
  font-family: 'David_Reg';
}

#form-header-text {
  font-size: 16px;
  margin-top: 8.5vh;
  font-family: 'David_Reg';
}

#form-header-required-text-mobile {
  font-size: 12px;
  margin-top: 30px;
  opacity: 0.5;
}

label {
  font-family: 'Graphik';
  font-size: 12pt;
  width: 100%;
  input {
    width: 100%;
    font-size: 16px;
    padding-bottom: 10px;
    background-color: #292929;
    border-style: hidden hidden solid;
    border-width: 0 0 1px;
  }
  p {
    padding-top: 10px;
    padding-bottom: 84px;
  }
}

#four-k-form {
  label {
    font-family: 'Graphik';
    font-size: 24pt;
    width: 100%;
    input {
      width: 100%;
      font-size: 32px;
      padding-bottom: 20px;
      background-color: #292929;
      border-style: hidden hidden solid;
      border-width: 0 0 2px;
    }
    p {
      padding-top: 20px;
      padding-bottom: 168px;
    }
  }
}

.adjacent-inputs#four-k-adj-inputs {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 168px;
  label:first-child {
    margin-right: 42px;
  }
  label input {
    font-size: 44px;
    font-family: 'Graphik';
    outline: none;
  }
  label p {
    font-size: 24px;
    font-family: 'Graphik';
  }
}

.adjacent-inputs {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 84px;
  label:first-child {
    margin-right: 21px;
  }
  label input {
    font-size: 22px;
    font-family: 'Graphik';
    outline: none;
  }
  label p {
    font-size: 12px;
    font-family: 'Graphik';
  }
}

.four-k-solo-label {
  width: 100%;
  p {
    font-size: 24px;
    font-family: 'Graphik';
  }
  input {
    font-size: 44px;
    font-family: 'Graphik';
  }
}

.four-k-solo-input {
  width: 100%;
  outline: none;
  font-size: 44px;
  font-family: 'Graphik';
}

.one-line-input-label {
  width: 100%;
  p {
    font-size: 12px;
    font-family: 'Graphik';
  }
  input {
    font-size: 22px;
    font-family: 'Graphik';
  }
}

.one-line-input {
  width: 100%;
  outline: none;
  font-size: 22px;
  font-family: 'Graphik';
}

.four-k-text-area {
  margin-bottom: 168px;
  .text-box {
    font-family: 'Graphik';
    font-size: 44px;
    border-width: 2px;
    border-style: solid;
    padding: 20px;
    .text-box-details {
      flex-direction: row;
      justify-content: space-between;
      .text-box-description {
        flex: 3;
        font-size: 24px;
        #mobile {
          font-size: 24px;
        }
      }
      .text-box-char-count {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        font-size: 24px;
        #mobile {
          font-size: 24px;
        }
      }
    }
    textarea {
      width: 100%;
      height: 100%;
      background-color: #292929;
      border-style: hidden;
      font-family: 'Graphik';
      font-size: 44px;
      line-height: get-line-height(44px, 60px);
      margin-top: 20px;
      overflow: hidden;
      outline: none;
    }
  }
}

.text-box-container {
  margin-bottom: 84px;
  .text-box {
    font-family: 'Graphik';
    font-size: 22px;
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    .text-box-details {
      flex-direction: row;
      justify-content: space-between;
      .text-box-description {
        flex: 3;
        font-size: 12px;
        #mobile {
          font-size: 12px;
        }
      }
      .text-box-char-count {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        font-size: 12px;
        #mobile {
          font-size: 12px;
        }
      }
    }
    textarea {
      width: 100%;
      height: 100%;
      background-color: #292929;
      border-style: hidden;
      font-family: 'Graphik';
      font-size: 22px;
      line-height: get-line-height(22px, 30px);
      margin-top: 10px;
      overflow: hidden;
      outline: none;
    }
  }
}

.file-upload-four-k {
  height: 440px;
  border-width: 2px;
  border-style: solid;
  margin-bottom: 168px;
  .upload {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px 28px 0;
    flex-direction: column;
    p {
      justify-self: center;
    }
    .upload-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      #drop-file {
        flex: 1;
      }
      #instructions {
        flex: 4;
        font-family: 'Graphik';
        font-size: 24px;
      }
      #limit {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-family: 'Graphik';
        font-size: 24px;
      }
    }
    .upload-icon {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: 'center';
      justify-content: 'center';
      p {
        flex: 1;
        height: 100%;
        align-self: center;
        font-family: 'Graphik';
        font-size: 44px;
      }
      img {
        flex: 1;
        align-self: center;
        justify-self: center;
        height: 82px;
      }
      div {
        flex: 1;
        height: 100%;
        align-self: center;
      }
    }
  }
}

.file-upload-box {
  height: 220px;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 84px;
  .upload {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 10px 14px 0;
    flex-direction: column;
    p {
      justify-self: center;
    }
    .upload-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      #drop-file {
        flex: 1;
      }
      #instructions {
        flex: 4;
        font-family: 'Graphik';
        font-size: 12px;
      }
      #limit {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-family: 'Graphik';
        font-size: 12px;
      }
    }
    .upload-icon {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: 'center';
      justify-content: 'center';
      p {
        flex: 1;
        height: 100%;
        align-self: center;
        font-family: 'Graphik';
        font-size: 22px;
      }
      img {
        flex: 1;
        align-self: center;
        justify-self: center;
      }
      div {
        flex: 1;
        height: 100%;
        align-self: center;
      }
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#four-k-capital-input {
  width: 100%;
  p {
    font-size: 24px;
    font-family: 'Graphik';
  }
}

#four-k-dollar-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(46pt + 20px);
  p {
    flex: 1;
    margin-top: 130px;
    margin-right: 4px;
    font-size: 44px;
    font-family: 'Graphik';
  }
}

#four-k-capital-label {
  font-family: 'Graphik';
  font-size: '24px';
  background-color: '#292929';
}

.capital-input {
  width: 100%;
  p {
    font-size: 12px;
    font-family: 'Graphik';
  }
}
.dollar-and-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(23pt + 10px);
  p {
    flex: 1;
    margin-top: 65px;
    margin-right: 2px;
    font-size: 22px;
    font-family: 'Graphik';
  }
}

.dollar-and-input-mobile {
  display: flex;
  height: calc(23pt + 10px);
  p {
    flex: 1;
    margin-top: -12px;
    margin-right: 2px;
    font-size: 22px;
    font-family: 'Graphik';
  }
}

// .launch-schedule-dropdown {
//   width: 100%;
//   font-size: 16px;
//   font-family: "Graphik";
//   color: white;
//   background-color: #292929;
//   border-style: hidden hidden hidden hidden;
//   .dropdown-wrapper {
//     border-bottom: 1px solid white;
//     .selected-option {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       .dropdown-arrow {
//         width: 30px;
//         height: 30px;
//       }
//     }
//     .dropdown-options {
//       font-family: "Graphik";
//       font-size: 16px;
//       .selected-option {
//         border-bottom: 1px #292929 solid;
//         padding: 8px 0 8px 10px;
//         background: #292929;
//       }
//       .unselected-option {
//         border-bottom: 1px #292929 solid;
//         padding: 8px 0 8px 10px;
//         color: #292929;
//         background-color: white;
//       }
//     }
//   }
//   .dropdown-wrapper-no-border {
//     border-bottom: 1px solid #292929;
//     .selected-option {
//       display: flex;
//       font-size: 16px;
//       font-family: "Graphik";
//       flex-direction: row;
//       justify-content: space-between;
//       .dropdown-arrow {
//         width: 30px;
//         height: 30px;
//       }
//     }
//     .dropdown-options {
//       font-family: "Graphik";
//       font-size: 12pt;
//       .selected-option {
//         border-bottom: 1px #292929 solid;
//         padding: 8px 0 8px 10px;
//         background: #292929;
//       }
//       .unselected-option {
//         border-bottom: 1px #292929 solid;
//         padding: 8px 0 8px 10px;
//         color: #292929;
//         background-color: white;
//       }
//     }
//   }
//   p {
//     font-size: 12pt;
//   }
// }

// .checkboxDropdownWrapper {
//   display: flex;
//   flex-direction: row;
//   font-size: 16px;
//   font-family: "Graphik";
//   justify-content: space-between;
//   border-bottom-width: 1px;
//   border-bottom-style: solid;
//   width: 100%;
// }

// .checkboxDropdown-no-border {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   border-bottom: none;
// }

// .selectedService {
//   color: #292929;
//   background-color: white;
//   width: 100%;
// }

// .hoverService {
//   color: white;
//   background-color: #292929;
//   width: 100%;
// }

// .servicesCheckboxActive {
//   height: 2vh;
//   width: 2vh;
//   border: 1px groove #292929;
//   align-self: center;
// }

// .servicesCheckboxInactive {
//   height: 2vh;
//   width: 2vh;
//   border: 1px outset grey;
//   align-self: center;
//   background: white;
// }

// .servicesCheckboxHover {
//   height: 2vh;
//   width: 2vh;
//   border: 1px outset white;
//   align-self: center;
//   background: #292929;
// }

.checkmark {
  justify-self: center;
  align-self: center;
  height: 95%;
  width: 95%;
}

#four-k-terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 98px;
  img {
    height: 34px;
  }
  .agree-terms {
    padding-left: 26px;
    font-size: 24px;
  }
}
.terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 49px;
  .agree-terms {
    padding-left: 13px;
    font-size: 12px;
  }
}

#four-k-submit {
  color: #292929;
  border: none;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  margin-bottom: 100px;
  font-family: 'Graphik';
  font-size: 32px;
}

.submit-btn {
  color: #292929;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  margin-bottom: 50px;
  font-family: 'Graphik';
  font-size: 16px;
}

#four-k-legal {
  font-family: 'Graphik';
  font-family: 24px;
  line-height: get-line-height(24px, 36pt);
  margin-bottom: 236px;
  p {
    font-size: 24px;
    color: grey;
  }
  p:first-child {
    color: white;
  }
}

.legal-stuff {
  font-family: 'Graphik';
  font-family: 12px;
  line-height: get-line-height(12px, 18pt);
  margin-bottom: 118px;
  p {
    font-size: 12px;
    color: grey;
  }
  p:first-child {
    color: white;
  }
}

#four-k-copyright {
  display: flex;
  flex-direction: row;
  margin: 250px 0 9vh;
  p {
    color: grey;
    &.copyright-text {
      flex: 1;
      font-size: 24px;
      font-family: 'Graphik';
    }
    &.rights-text {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      font-size: 24px;
      font-family: 'Graphik';
    }
  }
}

.copyright {
  display: flex;
  flex-direction: row;
  margin: 125px 0 5.75vh;
  p {
    color: grey;
    &.copyright-text {
      flex: 1;
      font-size: 12px;
      font-family: 'Graphik';
    }
    &.rights-text {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      font-family: 'Graphik';
    }
  }
}

.copyright-mobile {
  display: flex;
  flex-direction: column;
  padding: 80px 0 50px;
  p {
    color: grey;
    &.copyright-text {
      flex: 1;
      font-family: 'Graphik';
      font-size: 12px;
    }
    &.rights-text {
      flex: 1;
      display: flex;
      margin-top: 3vh;
      font-family: 'Graphik';
      font-size: 12px;
    }
  }
}
