$container-offset: (2 * $gap) !default;

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  @include desktop {
    max-width: $desktop - $container-offset;
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none; } }
  @include until-widescreen {
    &.is-widescreen {
      max-width: $widescreen - $container-offset; } }
  @include until-fullhd {
    &.is-fullhd {
      max-width: $fullhd - $container-offset; } }
  @include until-quadhd {
    &.is-quadhd {
      max-width: $quadhd - $container-offset; } }
  @include widescreen {
    max-width: $widescreen - $container-offset; }
  @include fullhd {
    max-width: $fullhd - $container-offset; }
  @include quadhd {
    max-width: $quadhd - $container-offset; }
  @include four-k {
    max-width: $four-k - $container-offset; } }
