#landing-bg {
	background-image: url(../imgs/bg1.jpg);
	background-size: cover;
	overflow-y: hidden;
	overflow-x: hidden;
}

#four-k-blurb {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	height: 100%;
	p {
		font-family: 'Graphik';
		font-size: 32px;
		justify-self: center;
		align-self: inherit;
		line-height: get-line-height(32px, 44px);
		text-transform: uppercase;
		font-weight: bold;

		&:last-child {
			margin-top: 32px;
		}

		a {
			text-decoration: none;
			color: rgba(255, 255, 255, 1);
			font-weight: normal;
			font-family: 'Graphik';
			line-height: get-line-height(32px, 44px);
			font-size: 32px;
			border-bottom: 1px solid white;

			&:hover {
				-webkit-transition: color 0.5s ease-out;
				-moz-transition: color 0.5s ease-out;
				-o-transition: color 0.5s ease-out;
				transition: color 0.5s ease-out;
				color: rgba(255, 255, 255, 0.65);
			}
		}
	}
}

#desktop-blurb {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	height: 100%;

	p {
		font-family: 'Graphik';
		font-size: 16px;
		justify-self: center;
		align-self: inherit;
		line-height: get-line-height(16px, 22px);
		text-transform: uppercase;
		font-weight: bold;

		&:last-child {
			margin-top: 16px;
		}

		a {
			text-decoration: none;
			color: rgba(255, 255, 255, 1);
			font-weight: normal;
			font-family: 'Graphik';
			line-height: 22px;
			font-size: 16px;
			border-bottom: 1px solid white;

			&:hover {
				-webkit-transition: color 0.5s ease-out;
				-moz-transition: color 0.5s ease-out;
				-o-transition: color 0.5s ease-out;
				transition: color 0.5s ease-out;
				color: rgba(255, 255, 255, 0.65);
			}
		}
	}
}

.vertical-logo {
	justify-content: center;
}

.vertical-logo-img {
	height: 75vh;
	align-self: center;
}

.landing-right {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.btn-animation {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.inquire-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: transparent;
			border: 2px solid white;
			padding: 10px 15px;
			outline: none;
			p {
				font-family: 'Graphik';
				font-size: 16px;
				color: white;
				padding-right: 27px;
			}
		}

		#four-k-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: transparent;
			border: 4px solid white;
			padding: 20px 30px;
			outline: none;
			p {
				font-family: 'Graphik';
				font-size: 32px;
				color: white;
				padding-right: 54px;
			}
			img {
				height: 24px;
			}
		}
	}
}

#mobile-landing {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	#mobile-inquire {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		outline: none;
	}
	.btn-animation {
		display: flex;
	}

	.inquire-mobile {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 120px;
		background-color: transparent;
		border: 2px solid white;
		padding: 10px 15px;
		font-family: 'Graphik';
		font-size: 16px;
		outline: none;
		margin-bottom: 10px;
		p {
			color: white;
			padding-right: 20px;
		}
		img {
			margin-right: 7px;
		}
	}

	#mobile-blurb {
		flex: 2;
		p {
			font-size: 16px;
			line-height: 22px;
			text-transform: uppercase;
			font-weight: bold;

			&:last-of-type {
				margin-top: 19px;
			}

			a {
				text-decoration: none;
				color: white;
				font-weight: normal;
				font-family: 'Graphik';
				line-height: 22px;
				font-size: 16px;
				border-bottom: 1px solid white;
			}
		}
	}
}
